import React from 'react';
import '../ContentPageLayout/ContentPageLayout.scss';
import './GeneralTerms.scss';
import PrivacyPolicy from '../PrivacyPolicy';

const GeneralTerms = () => (
  <div className="GeneralTerms">
    <ol className="ContentPageLayout-orderedList">
      <section className="ContentPageLayout-block">
        <h2 className="ContentPageLayout-subTitle">Definiciones</h2>
        <li className="ContentPageLayout-orderedListItem ContentPageLayout-hidden" />
        <ol className="ContentPageLayout-orderedList">
          <li className="ContentPageLayout-orderedListItem">
            <strong className="ContentPageLayout-strong">Préstamo</strong> es el contrato de
            préstamo personal por el cual el Prestamista concede, sujeto a las presentes Condiciones
            Generales, un préstamo al Prestatario.
          </li>
          <li className="ContentPageLayout-orderedListItem">
            <strong className="ContentPageLayout-strong">Prestamista</strong> es la sociedad NBQ
            Fund One, S.L, sociedad unipersonal, con N.I.F. número B-66128695, domiciliada en
            Barcelona, Calle Jordi Girona 29, código postal 08034.
          </li>
          <li className="ContentPageLayout-orderedListItem">
            <strong className="ContentPageLayout-strong">Intermediario</strong> es la sociedad NBQ
            Technology, S.A.U., sociedad unipersonal con N.I.F. número A-65.559.296, domiciliada en
            Barcelona, Calle Jordi Girona 29, código postal 08034, que actúa como intermediaria en
            la celebración de Préstamos en nombre y representación de la Prestamista.
          </li>
          <li className="ContentPageLayout-orderedListItem">
            <strong className="ContentPageLayout-strong">Solicitante de Préstamo</strong> es la
            persona física que manifiesta, mediante una declaración de intención a través de la
            Página Web, su propósito de solicitar al Prestamista un Préstamo.
          </li>
          <li className="ContentPageLayout-orderedListItem">
            <strong className="ContentPageLayout-strong">Solicitud de Préstamo</strong> es la
            declaración de intención realizada por parte del Solicitante de Préstamo y articulada a
            través de la cumplimentación de un formulario localizado en la Página Web, cuya
            finalidad es solicitar un Préstamo.
          </li>
          <li className="ContentPageLayout-orderedListItem">
            <strong className="ContentPageLayout-strong">Prestatario</strong> es el Solicitante de
            Préstamo una vez que el Intermediario, en nombre del Prestamista, ha aceptado su
            Solicitud de Préstamo.
          </li>
          <li className="ContentPageLayout-orderedListItem">
            <strong className="ContentPageLayout-strong">Parte</strong> es el Solicitante de
            Préstamo, el Intermediario, el Prestamista y el Intermediario, de forma individual; y
            las Partes son, conjuntamente, el Intermediario, el Prestamista y el Solicitante de
            Préstamo o el Prestatario.
          </li>
          <li className="ContentPageLayout-orderedListItem">
            <strong className="ContentPageLayout-strong">Página Web</strong> es la página Web en
            Internet del Intermediario, cuya dirección electrónica es{' '}
            <a
              className="Link"
              title="https://www.quebueno.es"
              href="https://www.quebueno.es"
              target="_blank"
              rel="noreferrer"
            >
              http://www.quebueno.es
            </a>
            .
          </li>
          <li className="ContentPageLayout-orderedListItem">
            <strong className="ContentPageLayout-strong">Día Hábil</strong> se entiende por tal el
            día de funcionamiento regular de los bancos u otras instituciones financieras en España,
            en particular del Banco de España, dada su importancia a efectos de la realización de
            operaciones bancarias y el cumplimiento de las obligaciones derivadas de las mismas. A
            los efectos de entender cumplida la obligación de pago por parte del prestatario se
            estará al día del vencimiento previsto en el calendario de pagos. En este sentido, si
            alguna de las fechas de pago coincidiera con una día no hábil (entendiéndose por tal
            sábados, domingos y días no considerados laborables de acuerdo con el calendario laboral
            aprobado por el gobierno de la Comunidad Autónoma en que resida el prestatario) y ello
            implicara que la transferencia (si este fuera el medio de pago) experimentara un retraso
            en su tramitación no se entenderá incumplida la obligación de pago.{' '}
          </li>
        </ol>
      </section>
      <div className="ContentPageLayout-separator" />
      <section className="ContentPageLayout-block">
        <h2 className="ContentPageLayout-subTitle">Objeto y régimen jurídico</h2>
        <li className="ContentPageLayout-orderedListItem ContentPageLayout-hidden" />
        <ol className="ContentPageLayout-orderedList">
          <li className="ContentPageLayout-orderedListItem">
            Las presentes Condiciones Generales tienen por objeto regular el Préstamo. El Préstamo
            se regirá por las Condiciones Generales y por las Condiciones Particulares. Además, en
            lo que corresponda, será de aplicación la Ley 22/2007, de 11 de julio, sobre
            comercialización a distancia de servicios financieros destinados a los consumidores;
            demás normativa de aplicación general a los consumidores; así como la normativa especial
            que rige la prestación de los servicios financieros en cada caso.
          </li>
          <li className="ContentPageLayout-orderedListItem">
            La Solicitud de Préstamo comporta que el Solicitante de Préstamo (y, en su caso, el
            Prestatario) acepta las presentes Condiciones Generales, respecto a las que declara que
            ha tenido acceso y ha podido descargarlas de la Página Web.
          </li>
          <li className="ContentPageLayout-orderedListItem">
            Asimismo, el Solicitante de Préstamo (o, en su caso, el Prestatario) declara
            expresamente que le ha sido proporcionada, previamente y con suficiente antelación a la
            celebración de cualquier contrato, la información detallada en los artículos 7 y 8 de la
            Ley 22/2007, de 11 de julio, de comercialización a distancia de servicios financieros
            destinados a consumidores y de la Ley 2/2009 de 31 de marzo, por la que se regula la
            contratación con los consumidores de préstamos o créditos hipotecarios y de servicios de
            intermediación para la celebración de contratos de préstamos o crédito. En particular,
            el Solicitante del Préstamo (o, en su caso, el Prestatario) declara que ha sido
            informado de su derecho de desistimiento, así como de los plazos, forma e instrucciones
            precisas para su ejercicio. La regulación del derecho de desistimiento se contiene en la
            Cláusula 9 de las presentes Condiciones Generales.
          </li>
        </ol>
      </section>
      <div className="ContentPageLayout-separator" />
      <section className="ContentPageLayout-block">
        <h2 className="ContentPageLayout-subTitle">Solicitud de préstamo</h2>
        <li className="ContentPageLayout-orderedListItem ContentPageLayout-hidden" />
        <ol className="ContentPageLayout-orderedList">
          <li className="ContentPageLayout-orderedListItem">
            Para ser Solicitante de Préstamo es imprescindible tener la residencia en España, ser
            mayor de edad y no tener restringida legalmente la capacidad de obrar.
          </li>
          <li className="ContentPageLayout-orderedListItem">
            El Solicitante de Préstamo habrá de realizar su Solicitud de Préstamo a través de la
            Página Web. En la Página Web encontrará un formulario, que habrá de cumplimentar y
            enviar al Intermediario.
          </li>
        </ol>
      </section>
      <div className="ContentPageLayout-separator" />
      <section className="ContentPageLayout-block">
        <h2 className="ContentPageLayout-subTitle">
          Concertación del contrato de préstamo y puesta a disposición del préstamo
        </h2>
        <li className="ContentPageLayout-orderedListItem ContentPageLayout-hidden" />
        <ol className="ContentPageLayout-orderedList">
          <li className="ContentPageLayout-orderedListItem">
            El Préstamo se considerará concertado, previo estudio de la solicitud por parte del
            Intermediario, cuando el Solicitante, una vez informado de que su solicitud ha sido
            aprobada, valide la operación haciendo clic en el enlace "
            <i>Acepto las condiciones particulares del contrato</i>" que se le muestra en el mensaje
            de correo electrónico en el que se le informa al Solicitante de la aprobación del
            Préstamo.
          </li>
          <li className="ContentPageLayout-orderedListItem">
            La Solicitud de Préstamo podrá rechazarse siempre que el Intermediario, lo considere
            oportuno y, en particular, cuando se estime que no es exacta o correcta la información
            proporcionada por el Solicitante de Préstamo.
          </li>
          <li className="ContentPageLayout-orderedListItem">
            Concertado el Préstamo, el Prestamista deberá realizar el siguiente Día Hábil una
            transferencia bancaria por el importe aceptado a la cuenta bancaria que haya sido
            facilitada por el Prestatario en la Solicitud de Préstamo.
          </li>
        </ol>
      </section>
      <div className="ContentPageLayout-separator" />
      <section className="ContentPageLayout-block">
        <h2 className="ContentPageLayout-subTitle">Condiciones económicas y coste del préstamo</h2>
        <li className="ContentPageLayout-orderedListItem ContentPageLayout-hidden" />
        <ol className="ContentPageLayout-orderedList">
          <li className="ContentPageLayout-orderedListItem">
            Los honorarios del Préstamo a satisfacer al Prestamista se componen de una comisión fija
            de apertura y de un interés que puede ser consultado en la Página Web cuando el cliente
            solicita el Préstamo. De igual modo, el cliente recibirá en el correo electrónico
            facilitado en el proceso de contratación, la Información Precontractual con todas las
            características económicas del préstamo suscrito.
          </li>
          <li className="ContentPageLayout-orderedListItem">
            Los intereses se recalcularán siempre a favor del cliente en caso de que, concertado el
            préstamo, la recepción efectiva de los fondos no se pudiera hacer efectiva por cursarse
            la transferencia fuera del horario de operativa bancaria. En este sentido los préstamos
            de NBQ no generarán intereses para los clientes hasta que éstos dispongan de los fondos.
          </li>
          <li className="ContentPageLayout-orderedListItem">
            La cuantía de los intereses dependerá del importe del Préstamo y de la duración del
            mismo. La T.A.E (Tasa anual equivalente) y la cuantía de los intereses se detallan en
            las condiciones particulares del contrato que se entregan a través de correo electrónico
            enviado a la dirección de correo facilitada por el cliente en el momento de la Solicitud
            del Préstamo. La T.A.E y los intereses variarán en función de la cuantía y plazo de pago
            del préstamos suscrito.
          </li>
          <li className="ContentPageLayout-orderedListItem">
            El tipo de interés es del 0,99% diario.
          </li>
          <li className="ContentPageLayout-orderedListItem">
            El Préstamo sólo podrá ser concedido y amortizado en Euros.
          </li>
          <li className="ContentPageLayout-orderedListItem">
            El Préstamo en situación de impago continuará generando los mismos intereses pactados
            que los generados por el préstamo si continuase activo. En este sentido, no se cobrarán
            intereses distintos de los pactados y no se incrementará el tipo de interés durante la
            vida del préstamo.
          </li>
          <li className="ContentPageLayout-orderedListItem">
            La cantidad mínima a solicitar es de 1 Euro y la máxima de 900 Euros.
          </li>
        </ol>
      </section>
      <div className="ContentPageLayout-separator" />
      <section className="ContentPageLayout-block">
        <h2 className="ContentPageLayout-subTitle">Reembolso del préstamo</h2>
        <li className="ContentPageLayout-orderedListItem ContentPageLayout-hidden" />
        <ol className="ContentPageLayout-orderedList">
          <li className="ContentPageLayout-orderedListItem">
            Al vencimiento, el Prestatario estará obligado a abonar al Prestamista el importe del
            Préstamo y los intereses. En todo caso, el Prestatario recibirá información de la suma
            total a pagar al vencimiento del Préstamo tanto antes de la contratación, como después
            de que ésta se perfeccione, y quede recogida en las Condiciones Particulares.
          </li>
          <li className="ContentPageLayout-orderedListItem">
            El abono de las cantidades devengadas de acuerdo con la Cláusula anterior se hará
            mediante cargo en la tarjeta de débito facilitada por el Prestatario en la Solicitud del
            Préstamo. El Prestamista cargará en la tarjeta bancaria la cantidad acordada a devolver
            de acuerdo con las Condiciones Particulares, para lo que el Prestatario presta su
            consentimiento. El Prestamista realizará el cargo en la tarjeta bancaria de dichas
            cantidades con el fin de satisfacer el cobro del préstamo, o de las cuotas de acuerdos
            de pago de estos.
          </li>
          <li className="ContentPageLayout-orderedListItem">
            En caso de que no pudiera hacerse efectivo el cobro a través de la tarjeta bancaria, el
            Prestamista podrá repetir tantas veces como considere hasta que la cantidad pendiente de
            pago sea satisfecha. Para ello, mediante el presente, el Prestatario autoriza
            expresamente al Prestamista a cargar en la tarjeta facilitada en la Solicitud del
            Préstamo, la cantidad pendiente de abono a la fecha del cargo en una o varias veces,
            hasta que la cantidad pendiente de pago sea satisfecha. Esto implica que, el Prestatario
            autoriza al Prestamista para cobrar a través de la tarjeta bancaria la comisión por
            reclamación de posiciones deudoras vencidas (Clausula 7) y todos aquellos gastos que se
            originen como consecuencia del impago de la deuda.
          </li>
          <li className="ContentPageLayout-orderedListItem">
            En cualquier momento durante la vigencia del Contrato de Préstamo, el Prestatario podrá
            realizar una amortización anticipada total del importe del Préstamo. En el momento de
            proceder a la amortización, el Prestatario habrá de abonar el importe del crédito y los
            intereses correspondientes a los días que hayan transcurrido desde la recepción de la
            transferencia realizada por el Prestamista. La suma total a pagar por el Prestatario el
            día de la amortización anticipada quedará recogida en las Condiciones Particulares. La
            amortización anticipada no genera ninguna comisión adicional.
          </li>
        </ol>
      </section>
      <div className="ContentPageLayout-separator" />
      <section className="ContentPageLayout-block">
        <h2 className="ContentPageLayout-subTitle">Reclamación de posiciones deudoras vencidas</h2>
        <li className="ContentPageLayout-orderedListItem ContentPageLayout-hidden" />
        <ol className="ContentPageLayout-orderedList">
          <li className="ContentPageLayout-orderedListItem">
            En caso de que llegado el día del vencimiento, el Prestamista no pudiera cobrar total o
            parcialmente las cantidades señaladas en la Cláusula 6.1, se iniciarán las actividades
            de reclamación de posiciones deudoras vencidas entre las que se incluyen correos
            electrónicos, SMS, llamadas telefónicas, gestión de expedientes con agencias externas,
            entre otros.
          </li>
          <li className="ContentPageLayout-orderedListItem">
            El Prestatario reconoce y acepta que, pasado el día del vencimiento y en caso de impago,
            el Prestamista, a través del Intermediario, puede ponerse en contacto con el Prestatario
            utilizando cualquiera de los datos de contacto facilitados por el Prestatario al
            solicitar el préstamo con el objetivo de reclamar la cantidad impagada.
          </li>
          <li className="ContentPageLayout-orderedListItem">
            En caso de que no se hayan satisfecho totalmente las cantidades debidas por el
            Prestatario al Prestamista, el Intermediario, en nombre del Prestamista, podrá, en las
            condiciones establecidas en la legislación vigente, informar al registro de morosos que
            considere oportuno y, en particular, a los registros de morosos de Asnef-Equifax.
            Asimismo, el Prestatario en mora queda informado de el Prestamista podrá encargar a un
            tercero la gestión de cobro.
          </li>
          <li className="ContentPageLayout-orderedListItem">
            La reclamación de posiciones deudoras vencidas genera costes adicionales al
            Intermediario. Dichos costes serán soportados por el cliente, y su naturaleza no es
            penalizar, correspondiendo a los costos incurridos por la gestión de la reclamación de
            posiciones deudoras vencidas.
          </li>
          <li className="ContentPageLayout-orderedListItem">
            Las cantidades que por la reclamación de posiciones deudoras vencidas deberá soportar el
            cliente son acumulativas y se desglosan de la siguiente manera:
          </li>
        </ol>
        <p>
          El día que se produzca el impago, comenzarán las actividades de reclamación, por las que
          el cliente deberá abonar 10€. A partir del día 15 desde la fecha en que se produjera el
          impago, el cliente deberá abonar 5€ por los gastos incurridos. A los 75 días del impago,
          el cliente deberá abonar 5€ por los gastos incurridos.
        </p>
      </section>
      <div className="ContentPageLayout-separator" />
      <section className="ContentPageLayout-block">
        <h2 className="ContentPageLayout-subTitle">
          Responsabilidad del Prestatario por suministro de información incorrecta o falsa
        </h2>
        <li className="ContentPageLayout-orderedListItem ContentPageLayout-hidden" />
        <ol className="ContentPageLayout-orderedList">
          <li className="ContentPageLayout-orderedListItem">
            En caso de que Prestatario hubiera suministrado en su Solicitud de Préstamo información
            incorrecta o falsa, el Prestamista tendrá derecho a la resolución automática del
            contrato antes de la fecha de vencimiento.
          </li>
          <li className="ContentPageLayout-orderedListItem">
            En caso de que el Prestamista decida resolver del contrato de Préstamo, el Prestamista
            tendrá derecho a recuperar el importe del Préstamo, los intereses generados hasta ese
            momento y la comisión de apertura detallados en las condiciones particulares del
            contrato de préstamo.
          </li>
        </ol>
      </section>
      <div className="ContentPageLayout-separator" />
      <section className="ContentPageLayout-block">
        <h2 className="ContentPageLayout-subTitle">Derecho de desistimiento</h2>
        <li className="ContentPageLayout-orderedListItem ContentPageLayout-hidden" />
        <ol className="ContentPageLayout-orderedList">
          <li className="ContentPageLayout-orderedListItem">
            De conformidad con lo previsto en el artículo 28.4 de la Ley de crédito al consumo el
            prestatario tiene derecho de desistimiento y se procede a poner a su disposición el
            siguiente modelo de{' '}
            <a
              className="Link"
              href="http://www.boe.es/buscar/act.php?id=BOE-A-2007-20555&tn=1&p=20140328&vd=#an"
              target="_blank"
              rel="noreferrer"
            >
              formulario de desistimiento
            </a>{' '}
            para poderlo, en su caso, ejercitar.{' '}
          </li>
          <li className="ContentPageLayout-orderedListItem">
            El Prestatario dispondrá de un plazo de 14 días naturales a contar desde la fecha de
            concertación del contrato de préstamo para ejercitar el derecho de desistimiento.
          </li>
          <li className="ContentPageLayout-orderedListItem">
            La intención de ejercitar el derecho de desistimiento se comunicará por escrito a la
            dirección que se establece en la Cláusula 12. La fecha de dicha comunicación deberá ser
            anterior al transcurso del plazo recogido en el párrafo anterior. El Prestatario deberá
            devolver al Prestamista transcurso del plazo recogido en el párrafo anterior. El
            Prestatario deberá devolver al Prestamista cualquier cantidad que haya recibido de éste
            con anterioridad al ejercicio de su derecho de desistimiento y deberá pagar los
            intereses originados durante el periodo de tiempo en el que el Prestatario tuviese
            disponibilidad sobre el Préstamo, en concreto y de conformidad con las tasas e intereses
            establecidas en el anexo de reembolso anticipado.
          </li>
        </ol>
      </section>
      <div className="ContentPageLayout-separator" />
      <section className="ContentPageLayout-block">
        <h2 className="ContentPageLayout-subTitle">Reclamaciones</h2>
        <li className="ContentPageLayout-orderedListItem ContentPageLayout-hidden" />
        <ol className="ContentPageLayout-orderedList">
          <li className="ContentPageLayout-orderedListItem">
            El cliente, puede dirigir sus reclamaciones al Servicio de Atención al Cliente (SAC)
            <ul className="ContentPageLayout-list">
              <li className="ContentPageLayout-listItem">
                llamando al siguiente número de teléfono gratuito:{' '}
                <a className="Link" href="tel:900 83 11 63">
                  900 83 11 63
                </a>
              </li>
            </ul>
            <ul className="ContentPageLayout-list">
              <li className="ContentPageLayout-listItem">
                enviando un correo electrónico a la dirección de{' '}
                <a className="Link" href="mailto:reclamaciones@quebueno.es">
                  reclamaciones@quebueno.es
                </a>{' '}
                <strong>(email exclusivo para las reclamaciones)</strong>
              </li>
            </ul>
            El Servicio de Atención al Cliente dispone de un plazo máximo de 1 mes para resolver las
            reclamaciones de acuerdo con su reglamento de funcionamiento
          </li>
          <li className="ContentPageLayout-orderedListItem">
            Si desea ponerse en contacto con el departamento jurídico de la entidad, tiene a su
            disposición la siguiente dirección para enviar una carta certificada. Su solicitud será
            atendida por un representante legal de la empresa.
            <div className="GeneralTerms-address">
              Carrer de Jordi Girona, 29 1B
              <br />
              08034 - Barcelona
              <br />A la atención del SAC
            </div>
          </li>
        </ol>
      </section>
      <div className="ContentPageLayout-separator" />
      <section className="ContentPageLayout-block">
        <h2 className="ContentPageLayout-subTitle">Consultas e incidencias</h2>
        <li className="ContentPageLayout-orderedListItem ContentPageLayout-hidden" />
        <ol className="ContentPageLayout-orderedList">
          <li className="ContentPageLayout-orderedListItem">
            Los clientes pueden dirigir sus consultas, solicitudes de información o cualquier otra
            incidencia o queja, a Atención al cliente por las siguientes vías:
            <ul className="ContentPageLayout-list">
              <li className="ContentPageLayout-listItem">
                Preguntas generales sobre el producto:{' '}
                <a
                  className="Link"
                  title="https://www.quebueno.es/preguntas-frecuentes"
                  href="https://www.quebueno.es/preguntas-frecuentes"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.quebueno.es/preguntas-frecuentes
                </a>
              </li>
              <li className="ContentPageLayout-listItem">
                Consultas o incidencias con el pago de su préstamo:{' '}
                <a className="Link" title="info@quebueno.es" href="mailto:info@quebueno.es">
                  info@quebueno.es
                </a>
              </li>
              <li className="ContentPageLayout-listItem">
                Solicitudes información y documentación de sus préstamos:{' '}
                <a className="Link" title="info@quebueno.es" href="mailto:info@quebueno.es">
                  info@quebueno.es
                </a>
              </li>
              <li className="ContentPageLayout-listItem">
                Llamando al teléfono gratuito 900 83 11 63.
              </li>
            </ul>
          </li>
          <li className="ContentPageLayout-orderedListItem">
            Atención al Cliente dará respuesta a las solicitudes recibidas en el plazo más breve
            posible.
          </li>
        </ol>
      </section>
      <div className="ContentPageLayout-separator" />
      <section className="ContentPageLayout-block">
        <h2 className="ContentPageLayout-subTitle">Datos de carácter personal</h2>
        <li className="ContentPageLayout-orderedListItem ContentPageLayout-hidden" />
        <PrivacyPolicy />
      </section>
      <div className="ContentPageLayout-separator" />
      <section className="ContentPageLayout-block">
        <h2 className="ContentPageLayout-subTitle">Otras disposiciones</h2>
        <li className="ContentPageLayout-orderedListItem ContentPageLayout-hidden" />
        <ol className="ContentPageLayout-orderedList">
          <li className="ContentPageLayout-orderedListItem">
            El Prestatario tiene el deber de informar al Intermediario de cualquier cambio en la
            información suministrada en la Solicitud de Préstamo.
          </li>
          <li className="ContentPageLayout-orderedListItem">
            El préstamo se formalizará en lengua castellana, sin embargo los documentos
            contractuales estarán disponibles en las lenguas co-oficiales a petición del consumidor.
          </li>
          <li className="ContentPageLayout-orderedListItem">
            El Prestamista tiene el derecho de ceder en su totalidad o en parte su posición
            contractual a un tercero que asuma los derechos del mismo. El Prestatario no podrá ceder
            su posición contractual. En cualquier caso, el prestatario tendrá derecho a oponer
            contra el tercero las mismas excepciones y defensas que le hubieren correspondido contra
            el acreedor originario, incluida la compensación.
            <br /> Se informará al prestatario de la cesión indicada en el apartado anterior,
            excepto cuanto el prestamista - de conformidad con el nuevo titular - siga prestando los
            servicios relativos al crédito del prestatario.
          </li>
          <li className="ContentPageLayout-orderedListItem">
            En caso de que cualquiera de las disposiciones incluidas en estas Condiciones fuera
            considerada nula, ilegal o de imposible cumplimiento en cualquier aspecto, o se
            modificase la legislación aplicable al Contrato, la validez, legalidad o exigibilidad de
            las disposiciones restantes incluidas en el Contrato no se verán en modo alguno
            afectadas o menoscabadas por tal circunstancia siempre que el contrato pueda subsistir
            sin dichas cláusulas.
          </li>
          <li className="ContentPageLayout-orderedListItem">
            Constituye el objeto social del NBQ Technology SAU entre otros, la intermediación para
            la celebración de contratos de préstamo o créditos, no hipotecarios, bajo la fórmula de
            pago aplazado, mediante la presentación, propuesta o realización de trabajos
            preparatorios de los mencionados contratos, incluida la puesta a disposición de tales
            contratos a los consumidores para su suscripción con el Prestamista.{' '}
          </li>
          <li className="ContentPageLayout-orderedListItem">
            El Intermediario ha suscrito una póliza de seguro de responsabilidad civil con la
            entidad aseguradora Arch Insurance Europe (Company) Limited y Liberty Mutual Insurance
            Europe Limited, por el importe mínimo legalmente establecido. El préstamo se formalizará
            en lengua castellana, sin embargo los documentos contractuales estarán disponibles en
            las lenguas cooficiales a petición del consumidor.{' '}
          </li>
        </ol>
      </section>
      <div className="ContentPageLayout-separator" />
      <section className="ContentPageLayout-block">
        <h2 className="ContentPageLayout-subTitle">Notificaciones</h2>
        <li className="ContentPageLayout-orderedListItem ContentPageLayout-hidden" />
        <ol className="ContentPageLayout-orderedList">
          <li className="ContentPageLayout-orderedListItem">
            Las comunicaciones se deberán realizar, si es posible, prioritariamente por correo
            electrónico.
          </li>
          <li className="ContentPageLayout-orderedListItem">
            Las partes manifiestan que en virtud de lo establecido en artículo 3.10 de la Ley
            59/2003, de 19 de diciembre, de firma electrónica, el contenido de cualquier
            comunicación remitida por correo electrónico entre las partes, remitido mediante el
            correo electrónico detallado en las condiciones particulares tendrá la consideración de
            documento firmado electrónicamente por cada una de las partes y con plena validez y
            eficacia.
          </li>
          <li className="ContentPageLayout-orderedListItem">
            Las notificaciones entre las Partes que deban realizarse por escrito y serán válidas si
            se efectúan correo electrónico, por buro-fax, por correo certificado con acuse de
            recibo, por fax en los domicilios y direcciones electrónicas que se detallan en la
            Solicitud de Préstamo y en las condiciones particulares, para cada una de las Partes.
          </li>
        </ol>
      </section>
      <div className="ContentPageLayout-separator" />
      <section className="ContentPageLayout-block">
        <h2 className="ContentPageLayout-subTitle">Ley aplicable y jurisdicción</h2>
        <li className="ContentPageLayout-orderedListItem ContentPageLayout-hidden" />
        <ol className="ContentPageLayout-orderedList">
          <li className="ContentPageLayout-orderedListItem">
            El Préstamo se interpretará y regirá de conformidad con la ley española. Serán
            competentes para resolver cualquier reclamación o controversia que pudiera plantearse en
            relación con la validez, interpretación o cumplimiento del Préstamo, los Juzgados y
            Tribunales del domicilio del Prestatario.
          </li>
          <li className="ContentPageLayout-orderedListItem">
            Las partes se someten, a su elección, para la resolución de los conflictos y con
            renuncia a cualquier otro fuero, a los juzgados y tribunales del domicilio del usuario.
          </li>
        </ol>
      </section>
    </ol>
  </div>
);

export default GeneralTerms;
